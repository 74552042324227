














  import { Component, Vue, Prop } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Cart } from '@one/types/dist/orderpath/app'

  const cart = namespace('cart')

  @Component({
    components: {
      OneIconLoader: () => import('~/components/molecules/icons/OneIconLoader.vue'),
      OneIconLink: () => import('~/components/molecules/navigations/OneIconLink.vue'),
    },
  })
  export default class CartIconComponent extends Vue {
    @cart.Getter getCurrentCartId: any
    @cart.Getter getBasicCartById: any
    @cart.Getter getCurrentCart: any
    @cart.Getter getCartItemsCount: any
    @cart.Getter getCartTotalPrice: any

    @Prop({
      type: String,
      required: true,
    })
    icon!: string;

    get isCartAvailable() {
      return this.$utils.isPurchaseEnabledOrAuthenticated && !this.$auth.passwordChangeIsRequired()
    }

    get currentCartName(): string | null {
      // eslint-disable-next-line max-len
      const bcart: Cart.Responses.BasicCartInfo | null = this.getBasicCartById(this.getCurrentCartId)
      return (bcart && bcart.name) || null
    }

    get itemsInCart(): number {
      if (this.getCurrentCartId) {
        return this.getCartItemsCount(this.getCurrentCartId)
      }
      return 0
    }
  }
